import Raicon from 'raicon';
import InsurancePolicyForm from './insurancePolicyForm';
import showAttentionNeededForEvents from '../shared/showEventProgressByPercentage';
import InsurancePolicyChart from './insurancePolicyChart';
import { queryParamExistUrl } from '@/layouts/application/utils/checkParamsExist';
import { add10Referees } from '../add10Referees';
import { handleToggleDreamPlanning } from '../handleToggleDreamPlanning';
import AddFamilyMemberAction from '../dreams/addFamilyMemberAction';
import dynamicIncludedMonth from '../dynamicIncludedMonth';
import Tablesort from 'tablesort';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import AssetInvestmentForm from '../netwealths/assetInvestmentForm';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';
import { debounce } from '@/helpers/index';

class ConsultantInsurancesController {
  constructor() {
    this.sendPolicyCopySelector = '#send-copy-btn';
    this.insuranceListInputSelector = '.insurance-list input';
    this.usernameGroupSelector = '#finconnect-username';
    this.emergencyFinconnectUsernameSelector = '#ec-finconnect-username';
    this.usernameWarningSelector = '#username-warning';
    this.saveEmergencyNetworkSelector = '#save-emergency-network';
    this.emergencyNetworkFormSelector = '#emergency-network-form';
    this.warningGroupSelector = '#emergency-network-warning';
    this.forceSaveSelector = '#save-without-share-btn';
    this.reviseFormSelector = '#back-to-revise-btn';
  }

  index() {
    this.setupInsurances();
    this.setupEmergencyNetworks();
    showAttentionNeededForEvents();
    this.triggerPopupPolicyWakeup();
    this.getCurrentTotalLiabilities();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    handleToggleDreamPlanning(['#add-policy-button', '#edit-policy-button', '#insurance-policy-form', '#open-investment']);
    this.handleToggleDreamPlanning();
    bulkDeleteItem('#bulk-delete-insurance', '.insurance-checkbox');
    bulkDeleteItem('#bulk-delete-investment', '.investment-checkbox');
    //include portfolio summary chart to index
    new InsurancePolicyChart().setup();
    showAttentionNeededForEvents();
    this.getCurrentTotalLiabilities();
    this.setupTableSort();
    //include investment form to index insurance
    this.addTriggerOpenModalEvents();
    handleHideSignSgd('#currency-select', '.property-currency');
  }

  portfolioSummary() {
    new InsurancePolicyChart().setup();
    showAttentionNeededForEvents();
    this.getCurrentTotalLiabilities();
    this.setupTableSort();
  }

  triggerPopupPolicyWakeup() {
    if (queryParamExistUrl('policy_type')) {
      $('#policy-button-init')[0].click();
    }
  }

  setupTableSort() {
    let search = '_table';
    Object.keys(localStorage)
      .filter((key) => key.endsWith(search))
      .map((key) => {
        localStorage.removeItem(key);
      });

    $('.tablesort').each((_, table) => {
      new Tablesort(table);

      table.addEventListener('afterSort', ({ target }) => {
        const tableSelector = $(target);
        const tableName = tableSelector.attr('id');
        const insuranceIds = [];

        localStorage.removeItem(tableName);
        tableSelector.find('tr').each((_, data) => {
          const $data = $(data);
          if ($data.data('insurance-id')) {
            insuranceIds.push($data.data('insurance-id'));
          }
        });

        localStorage.setItem(tableName, insuranceIds);
      });
    });
  }

  setupInsurances() {
    new InsurancePolicyForm().setup();
  }

  setupEmergencyNetworks() {
    $('body').on(
      'modal:afterSetContent',
      '#edit-emergency-network-btn, #new-emergency-network-btn, #emergency-network-form, #edit-emergency-network-form, #back-link',
      (e) => {
        const usernameGroup = $(this.usernameGroupSelector);
        const selectedId = $(e.target).val();
        const usernameInput = usernameGroup.find('input');
        this.handleSelectAllBtn();
        this.handleDeselectAllBtn();
        this.handleSelectFinConnectUsername();
        this.handleVerifyUsernameInEmergencyForm(usernameInput.val(), selectedId);
        new AddFamilyMemberAction().setup('.emergency-network-modal');
        this.toggleUsernameGroup();
        $(this.sendPolicyCopySelector).on('change', (e) => {
          this.toggleUsernameGroup();
        });
        this.handleWarningOnsubmit();
        $(this.emergencyFinconnectUsernameSelector).on('change', debounce((event) => {
          this.handleVerifyUsernameInEmergencyForm($(event.target).val());
        }, 400));

        const clientId = $('body').data('client-id');
        const hrefPath = new URL( $(e.target).attr('href'), window.location.origin).pathname;

        if (hrefPath === Routes.new_consultant_client_emergency_network_path(clientId)) {
          $(this.sendPolicyCopySelector).prop('checked', true);
          $(this.usernameGroupSelector).show();
          $(this.insuranceListInputSelector).prop('checked', true);
        }
      },
    );
  }

  getCurrentTotalLiabilities() {
    const getCurrent = '#get-current-liabilities';
    const totalLiabilities = '#total-liabilities';

    $('body').on('modal:afterSetContent', '#death-event, #edit-event-event_death-form', () => {
      $(getCurrent).on('click', () => {
        const liabilities = $(getCurrent).attr('data-current-liabilities');
        $(totalLiabilities).val(liabilities);
      });
    });
  }

  handleSelectAllBtn() {
    $('#select-all-btn').on('click', () => {
      $(this.insuranceListInputSelector).prop('checked', true);
    });
  }

  handleDeselectAllBtn() {
    $('#deselect-all-btn').on('click', () => {
      $(this.insuranceListInputSelector).prop('checked', false);
    });
  }

  handleToggleDreamPlanning() {
    $('body').on('modal:afterSetContent', '#add-policy-button, #edit-policy-button, #insurance-policy-form', () => {
      new dynamicIncludedMonth().setup('#premium-interval-type');
    });
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#open-investment, #asset-investment-form, #back-link', () => {
      this.toggleInvestmentRecurringEvent();
      new AssetInvestmentForm('.insurance-modal').setup();
    });
  }

  toggleInvestmentRecurringEvent() {
    const $checkbox = $('#investment-recurring-checkbox');
    const $recurringForm = $('.recurring-investment-form');

    this.toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  toggleElementByCheckboxEvent($checkbox, $form) {
    if ($checkbox.is(':checked')) {
      $form.show();
    } else {
      $form.hide();
    }

    $checkbox.on('change', function () {
      if (this.checked) {
        $form.show();
      } else {
        $form.hide();
      }
    });
  }

  toggleUsernameGroup() {
    if($(this.sendPolicyCopySelector).is(':checked')) {
      $(this.usernameGroupSelector).show();
    } else {
      $(this.usernameGroupSelector).hide();
    }
  }

  handleSelectFinConnectUsername() {
    $('body').on('click', '.insurance-person-input', (e) => {
      const usernameGroup = $(this.usernameGroupSelector);
      const usernames = usernameGroup.data('username');
      const memberMissingList = usernameGroup.data('member-missing');
      const selectedId = $(e.target).val();
      let usernameInput = usernameGroup.find('input');
      let selectedUsername;
      for(let i = 0; i < usernames.length; i++) {
        if(usernames[i][0] == selectedId) {
          selectedUsername = usernames[i][1];
          usernameInput.val(selectedUsername);
          usernameInput.attr('readonly', true);
          break;
        }
      }

      if(selectedUsername == undefined) {
        usernameInput.removeAttr('readonly');
        usernameInput.val('');
      }

      this.handleVerifyUsernameInEmergencyForm(usernameInput.val(), selectedId);
      this.toggleMissingDataFlag(memberMissingList, selectedId);
    })
  }

  handleWarningOnsubmit() {
    let formSubmitted = false;

    $(this.saveEmergencyNetworkSelector).on('click', (e) => {
      if($(e.target).data('missing-info') == 'true' && $(this.sendPolicyCopySelector).is(':checked')) {
        if (formSubmitted) {
          return;
        }

        e.preventDefault();
        const clientId = $('body').data('client-id');

        $.ajax({
          url: Routes.verify_exists_username_consultant_client_emergency_networks_index_path(clientId),
          type: 'GET',
          data: {
            username: $(this.emergencyFinconnectUsernameSelector).val(),
          },
          success: (data) => {
            if (data['exists']) {
              formSubmitted = true;
              $(this.saveEmergencyNetworkSelector).trigger('click');
            } else {
              this.toggleFormAndWarningGroup(true);

              $(this.forceSaveSelector).on('click', () => {
                formSubmitted = true;
                $(this.sendPolicyCopySelector).prop('checked', false)
                $(this.saveEmergencyNetworkSelector).trigger('click');
              })
      
              $(this.reviseFormSelector).on('click', () => {
                this.toggleFormAndWarningGroup(false);
              })
            }
          },  
        });
      }
    })
  }

  toggleFormAndWarningGroup(waring=true) {
    if(waring) {
      $(this.emergencyNetworkFormSelector).hide();
      $(this.warningGroupSelector).removeClass('d-none');
    } else {
      $(this.emergencyNetworkFormSelector).show();
      $(this.warningGroupSelector).addClass('d-none');
    }
  }

  toggleMissingDataFlag(memberMissingList, selectedId) {
    if(memberMissingList.includes(parseInt(selectedId))) {
      $(this.saveEmergencyNetworkSelector).data('missing-info', 'true');
    } else {
      $(this.saveEmergencyNetworkSelector).data('missing-info', 'false');
    }
  }

  handleVerifyUsernameInEmergencyForm(username, familyMemberId = '') {
    if(username == '') {
      $(this.usernameWarningSelector).hide();
      $(this.usernameWarningSelector).text('');

      return;
    }

    const clientId = $(this.emergencyFinconnectUsernameSelector).data('client-id');
    $.ajax({
      url: Routes.verify_username_consultant_client_family_index_path(clientId),
      type: 'GET',
      data: {
        username,
        family_member_id: familyMemberId,
      },
      success: (data) => {
        if(data['warning']) {
          $(this.usernameWarningSelector).text(data['warning']);
          $(this.usernameWarningSelector).addClass('text-black');
          $(this.usernameWarningSelector).show();
        } else {
          $(this.usernameWarningSelector).hide();
          $(this.usernameWarningSelector).text('');
        }
      },  
    });
  }
}

Raicon.register('consultant/insurances', ConsultantInsurancesController);
