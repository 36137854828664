export default function handleLockForm(container) {
  container.forEach((element) => {
    const clientEditable = $(element).data('client-editable');
    const isSharedPolicyWithEc = $(element).data('is-shared-policy-with-ec');
    
    if (!clientEditable || isSharedPolicyWithEc) {
      $(element).find('input, textarea, select').prop('disabled', true);
    }
  });
}
