import Raicon from 'raicon';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';

const images = require.context('@/images/icons/sidebar', true);
const imagePath = (name) => images(name, true);

class ClientsEmergencyNetworksController {
  constructor() {
    this.sendPolicyCopySelector = '#send-copy-btn';
    this.usernameGroupSelector = '#finconnect-username'
    this.newMemberFormSelector = '#new_member_form';
    this.insuranceListInputSelector = '.insurance-list input';
    this.copyUsernameIconSelector = '.copy-username-icon';
    this.defaultImageCheckPath = imagePath('./check.svg');
    this.defaultImageDuplicatePath = imagePath('./duplicate.svg');
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  index() {
    this.setupClipboardCopy();
  }

  new() {
    this.setupForm();
  }

  create() {
    this.setupForm();
  }

  edit() {
    this.setupForm();
  }

  newMember() {
    $(this.sendPolicyCopySelector).prop('checked', true);
    $(this.insuranceListInputSelector).prop('checked', true);
    this.setupForm();
  }

  createNewMember() {
    this.setupForm();
  }

  setupForm() {
    this.toggleUsernameGroup();
    $(this.sendPolicyCopySelector).on('change', (e) => {
      this.toggleUsernameGroup();
      this.handleRenderNewMember();
    });
    this.handleSelectAllBtn()
    this.handleDeselectAllBtn()
    this.handleSelectMemberBtn()
    this.handleUsernameCreateLead()
  }

  handleUsernameCreateLead() {
    $('input:submit').on('click', (e) => {
      e.preventDefault();
      let username = $('#emergency_network_finconnect_username').val();
      let sendPolicyCopychecked = $(this.sendPolicyCopySelector).is(':checked');
      if (sendPolicyCopychecked) {
        $.ajax({
          type: 'GET',
          url: Routes.list_username_for_ec_clients_emergency_networks_path(),
          data: { username: username },
          success: (data) => {
            if (data['data']) {
              $('#create_emergency_network_form').off('submit').submit();
            } else {
              $('#finconnect-username .invalid-feedback').show();
            }
          },
          error: (xhr, status, error) => {
            console.error('Error AJAX: ', status, error);
          }
        });
      } else {
        $('#create_emergency_network_form').off('submit').submit();
      }
    });
  }

  setupClipboardCopy() {
    $(this.copyUsernameIconSelector).on('click', (e) => {
      const $icon = $(e.currentTarget);
      const username = $icon.data('username');
      
      if (username) {
        navigator.clipboard.writeText(username).then(() => {
          $icon.attr('src', this.defaultImageCheckPath);
          setTimeout(() => {
            $icon.attr('src', this.defaultImageDuplicatePath);
          }, 1000);
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      }
    });
  }

  toggleUsernameGroup() {
    let memberId = $('.member-radio .form-check-input:checked')[0].id.replace('emergency_network_family_member_id_', '');
    let sendPolicyCopychecked = $(this.sendPolicyCopySelector).is(':checked');

    if(sendPolicyCopychecked) {
      $(this.usernameGroupSelector).show();
      if (memberId == 'false') {
        $(this.newMemberFormSelector).hide();
      }
    } else {
      $(this.usernameGroupSelector).hide();
    }
  }

  handleRenderNewMember() {
    let memberId = $('.member-radio .form-check-input:checked')[0].id.replace('emergency_network_family_member_id_', '');
    let sendPolicyCopychecked = $(this.sendPolicyCopySelector).is(':checked');

    if (memberId == 'false' && !sendPolicyCopychecked) {
      $(this.usernameGroupSelector).hide();
      $(this.newMemberFormSelector).show();
    }
  }

  handleSelectMemberBtn() {
    $('.member-radio .form-check-label').on('click', (e) => {
      let memberId = $(e.target).attr('for').split('_').pop();
      let sendPolicyCopychecked = $(this.sendPolicyCopySelector).is(':checked');

      if (memberId !== 'false') {
        window.location.href = Routes.edit_clients_emergency_network_path({ id: memberId, send_policy_copy_checked: sendPolicyCopychecked });
        return;
      }

      window.location.href = Routes.new_member_clients_emergency_networks_path();
    });
  }

  handleSelectAllBtn() {
    $('#select-all-btn').on('click', () => {
      $(this.insuranceListInputSelector).prop('checked', true);
    });
  }

  handleDeselectAllBtn() {
    $('#deselect-all-btn').on('click', () => {
      $(this.insuranceListInputSelector).prop('checked', false);
    });
  }

  handleSelectFinConnectUsername() {
    $('body').on('click', '.insurance-person-input', (e) => {
      const usernameGroup = $(this.usernameGroupSelector);
      const usernames = usernameGroup.data('username');
      const selectedId = $(e.target).val();
      let usernameInput = usernameGroup.find('input');
      let selectedUsername;
      for(let i = 0; i < usernames.length; i++) {
        if(usernames[i][0] == selectedId) {
          selectedUsername = usernames[i][1];
          usernameInput.val(selectedUsername);
          usernameInput.attr('disabled', 'disabled');
          break;
        }
      }

      if(selectedUsername == undefined) {
        usernameInput.removeAttr('disabled');
        usernameInput.val('');
      }
    })
  }

}

Raicon.register('clients/emergencyNetworks', ClientsEmergencyNetworksController);
