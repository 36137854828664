import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import AddFamilyMemberAction from '../dreams/addFamilyMemberAction';
import { handleInsurerOther } from '../shared/handleInsurerOther';
import { handlePolicyBenefit } from '../shared/handlePolicyBenefit';
import { handleTotalPaidOnChange } from '../netwealths/shared/calculateTotalPaidAmount';
import { formatCurrency, formatDate } from '@/layouts/application/utils/formats';
import { setDataForSelect2 } from '@/layouts/application/utils/select2Util';
export default class InsurancePolicyForm {
  insurancePersonDob = undefined;

  policyInceptionDate = undefined;
  policyInceptionAge = undefined;
  policyEndDate = undefined;
  policyEndDateCoverage = undefined;

  policyPaymentStartYear = undefined;
  policyPaymentEndYear = undefined;
  policyPaymentCoverage = undefined;
  policyPaymentStartAge = undefined;
  policyPaymentEndAge = undefined;

  constructor() {
    this.buttonCreatePolicySelector = '#add-policy-button';
    this.buttonEditPolicySelector = '#edit-policy-button';
    this.policyInsuranceFormSelector = '#insurance-policy-form';
    this.insurancePersonFieldSetSelector = '.insurance-person-input';
    this.insurancePersonListSelector = '#insurance-person-list';
    this.insuredPersonSelector = '#insurance-person-list .invalid-feedback';
    this.buttonInitPolicy = '#policy-button-init';

    this.policyInceptionDateSelector = '.policy-inception-date';
    this.policyInceptionAgeSelector = '.policy-inception-age';
    this.policyEndDateSelector = '.policy-end-date';
    this.policyEndAgeSelector = '.policy-end-age';
    this.policyEndCoverageSelector = '.policy-end-coverage';
    this.paymentStartYearSelector = '#payment-start-year';
    this.paymentStartYearAgeSelector = '#payment-start-year-age';
    this.paymentEndYearSelector = '#payment-end-year';
    this.paymentEndYearAgeSelector = '#payment-end-year-age';
    this.paymentEndYearCoverageSelector = '#payment-end-year-coverage';
    this.insuranceBack = '#back-link';
    this.tillAgeSelector = '.till-age-input';
    this.sumAssuredSelector = '.sum-assured-input';
    this.clientId = $('body').data('client-id');
    this.defaultPremium = {
      cpf_medisave: 0,
      cash_outlay: 0,
      total_premium: 0,
    };
  }

  setup() {
    this.setOpenPolicyModalEvents();
  }

  setupForClientPortal() {
    this.listActions(true);
    // new AddFamilyMemberAction().setup('.insurance-mobile');
  }

  setInsurancePersonDob(dob) {
    this.insurancePersonDob = dob;
  }

  setOpenPolicyModalEvents() {
    $('body').on(
      'modal:afterSetContent',
      `${this.buttonCreatePolicySelector}, ${this.buttonEditPolicySelector}, ${this.policyInsuranceFormSelector}, ${this.buttonInitPolicy}, ${this.insuranceBack}`,
      () => {
        this.listActions();
        new AddFamilyMemberAction().setup('.insurance-modal');
      },
    );
  }

  listActions(isClient = false) {
    this.setExistedValue();
    this.setOnchangeEventForInsurancePerson();
    this.setOnchangeEventForPolicyInceptionDate();
    this.setOnchangeEventForPolicyEndDate();
    this.setOnChangeEventForPaymentStartYear();
    this.setOnChangeEventForPaymentEndYear();
    this.calculationForPaymentDateCoverage();
    this.calculationForPaymentStartYearAge();
    this.calculationForPaymentEndYearAge();
    this.calculationForPaymentStartYearAge();
    this.calculationForpolicyInceptionAge();
    this.calculationForPolicyEndDateCoverageAndAge();
    this.fetchListShieldPlansAndListRiders(isClient);
    this.filledUpPremiumOnShieldPlanAndRiderChange(isClient);
    this.allowRiderNameSelectionClear();
    this.handleErrorMessages();
    handleInsurerOther();
    handleTotalPaidOnChange();
    handlePolicyBenefit();
  }

  setExistedValue() {
    const $personSelected = $(this.insurancePersonListSelector).find('input:checked');
    const $policyInceptionDate = $(this.policyInceptionDateSelector);
    const $policyEndDate = $(this.policyEndDateSelector);
    const $paymentStartYear = $(this.paymentStartYearSelector);
    const $paymentEndYear = $(this.paymentEndYearSelector);
    if ($personSelected.length > 0) {
      this.setInsurancePersonDob(new Date(JSON.parse($personSelected.val()).dob));
    } else {
      this.setInsurancePersonDob(undefined);
    }

    this.policyInceptionDate = new Date($policyInceptionDate.val());
    this.policyEndDate = new Date($policyEndDate.val());
    this.policyPaymentStartYear = $paymentStartYear.val();
    this.policyPaymentEndYear = $paymentEndYear.val();
  }

  setOnchangeEventForInsurancePerson() {
    $(this.insurancePersonFieldSetSelector).on('click', ({ target }) => {
      this.setInsurancePersonDob(new Date(JSON.parse(target.value).dob));
      this.calculationForpolicyInceptionAge();
      if (this.policyEndDate != 'Invalid Date') this.calculationForPolicyEndDateDefaultValue();
      this.calculationForPolicyEndDateCoverageAndAge();
      this.calculationForPaymentStartYearAge();
      this.calculationForPaymentEndYearAge();

      if (
        $('#insurance-policy-form').data('auto-generate-end-date') &&
        $('#insurance-policy-form').data('action') === 'new'
      ) {
        this.calculationForPolicyEndDateDefaultValue();
        this.setPolicyEndDateAgeDefaultValue();
        this.calculationForPaymentEndYearDefaultValue();
        this.setPaymentEndYearAgeDefaultValue();
      }
    });
  }

  calculationForPolicyEndDateDefaultValue(inceptionDatePolicy = this.insurancePersonDob) {
    const policyEndDateDatePicker = $(this.policyEndDateSelector);
    const policyEndDateTextInput = $('.datepicker[placeholder="Policy End Date"][type="text"]');
    const inceptionDate = new Date(inceptionDatePolicy);
    inceptionDate.setDate(inceptionDate.getDate() - 1); // Set a date is 1 day before age 100

    const date = ('0' + inceptionDate.getDate()).slice(-2); // Get day with 2 digits
    const month = ('0' + (inceptionDate.getMonth() + 1)).slice(-2); // Get month with 2 digits

    policyEndDateDatePicker.val(`${this.calculationInsurancePersonDob()}-${month}-${date}`);
    policyEndDateTextInput.val(`${date}/${month}/${this.calculationInsurancePersonDob()}`);
    this.policyEndDate = new Date(policyEndDateDatePicker.val());
  }

  calculationForPaymentEndYearDefaultValue() {
    this.policyPaymentEndYear = this.calculationInsurancePersonDob();
  }

  calculationInsurancePersonDob() {
    return this.insurancePersonDob.getFullYear() + 100;
  }

  setPolicyEndDateAgeDefaultValue() {
    $(this.policyEndAgeSelector).html(99);
  }

  setPaymentEndYearAgeDefaultValue() {
    $(this.paymentEndYearAgeSelector).html(99);
  }

  setOnchangeEventForPolicyInceptionDate() {
    $(this.policyInceptionDateSelector).on('change', ({ target }) => {
      this.policyInceptionDate = new Date(target.value);
      this.calculationForpolicyInceptionAge();
      if (this.policyEndDate == 'Invalid Date') {
        this.calculationForPolicyEndDateDefaultValue();
      }
      this.calculationForPolicyEndDateCoverageAndAge();
    });
  }

  calculationForpolicyInceptionAge() {
    if (!this.insurancePersonDob || !this.policyInceptionDate) {
      return false;
    }

    this.policyInceptionAge = getAge(this.insurancePersonDob, this.policyInceptionDate);

    $(this.policyInceptionAgeSelector).html(this.policyInceptionAge);
  }

  setOnchangeEventForPolicyEndDate() {
    $(this.policyEndDateSelector).on('change', ({ target }) => {
      this.policyEndDate = new Date(target.value);
      this.calculationForPolicyEndDateCoverageAndAge();
    });
  }

  calculationForPolicyEndDateCoverageAndAge() {
    if (this.policyEndDate && this.policyInceptionDate) {
      this.policyEndDateCoverage = this.policyEndDate.getFullYear() - this.policyInceptionDate.getFullYear();

      if (Number.isInteger(this.policyEndDateCoverage) && this.policyEndDateCoverage > 0) {
        $(this.policyEndCoverageSelector).html(this.policyEndDateCoverage + ' Years');
      } else if (this.policyEndDateCoverage < 0) {
        $(this.policyEndCoverageSelector).html('Completed');
      }
    }

    if (this.policyEndDate && this.insurancePersonDob) {
      this.policyEndDateAge = getAge(this.insurancePersonDob, this.policyEndDate);

      $(this.policyEndAgeSelector).html(this.policyEndDateAge);
      this.setTillAgeOnPaymentEndDateChange();
    }
  }

  setOnChangeEventForPaymentStartYear() {
    $(this.paymentStartYearSelector).on('change', ({ target }) => {
      this.policyPaymentStartYear = $(target).val();
      this.calculationForPaymentDateCoverage();
      this.calculationForPaymentStartYearAge();
    });
  }

  setOnChangeEventForPaymentEndYear() {
    $(this.paymentEndYearSelector).on('change', ({ target }) => {
      this.policyPaymentEndYear = $(target).val();
      this.calculationForPaymentDateCoverage();
      this.calculationForPaymentEndYearAge();
    });
  }

  calculationForPaymentDateCoverage() {
    if (this.policyPaymentEndYear) {
      const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
      const paymentEndYear = new Date(this.policyPaymentEndYear);

      this.policyPaymentCoverage = paymentEndYear.getFullYear() - currentDate.getFullYear();
      if (currentDate > paymentEndYear) {
        $(this.paymentEndYearCoverageSelector).html('Completed');
      } else {
        $(this.paymentEndYearCoverageSelector).html(this.policyPaymentCoverage + ' Years');
      }
    }
  }

  calculationForPaymentStartYearAge() {
    if (this.insurancePersonDob && this.policyPaymentStartYear) {
      this.policyPaymentStartAge = this.policyPaymentStartYear - this.insurancePersonDob.getFullYear();

      $(this.paymentStartYearAgeSelector).html(this.policyPaymentStartAge);
    }
  }

  calculationForPaymentEndYearAge() {
    if (this.insurancePersonDob && this.policyPaymentEndYear) {
      const policyPaymentEndDate = new Date(this.policyPaymentEndYear);
      this.policyPaymentEndAge = policyPaymentEndDate.getFullYear() - this.insurancePersonDob.getFullYear();

      $(this.paymentEndYearAgeSelector).html(this.policyPaymentEndAge);
    }
  }

  handleErrorMessages() {
    $('[name="insurance_policy[policy_name]"]').on('change', ({ target }) => {
      if (target.value) {
        $(target).removeClass('is-invalid');
        $(target).parent().find('invalid-feedback').remove();
      }
    });
  }

  setTillAgeOnPaymentEndDateChange() {
    const benefitLength = $(this.tillAgeSelector).length;
    for (let i = 0; i < benefitLength; i++) {
      let tillAgeValue = $($(this.tillAgeSelector)[i]).val();
      let sumAssuredValue = $($(this.sumAssuredSelector)[i]).val();
      let invalidFeedback = $($(this.tillAgeSelector)[i]).parent().find('div[class="invalid-feedback"]');
      if ((tillAgeValue == '' || sumAssuredValue == '' || sumAssuredValue == '0') && invalidFeedback.length == 0) {
        $($(this.tillAgeSelector)[i]).val(this.policyEndDateAge);
      }
    }
  }

  fetchListShieldPlansAndListRiders(isClient = false) {
    $('#insurer-list-automatic').on('change', ({ target }) => {
      let url = "";
      if(isClient) {
        url =
          Routes.get_list_shield_plan_and_list_rider_by_insurer_clients_shield_premiums_path({
            client_id: this.clientId,
            name: target.value,
          })
      } else {
        url = 
          Routes.get_list_shield_plan_and_list_rider_by_insurer_consultant_shield_premiums_path({
            client_id: this.clientId,
            name: target.value,
          })
      }
      $.get(url).done((data) => {
        let shieldPlanNames = data.shield_plan_names.map((item) => ({ id: item, text: item }));
        let riderNames = data.rider_names.map((item) => ({ id: item, text: item }));
        setDataForSelect2('.policy-name-list', shieldPlanNames, false);
        setDataForSelect2('.rider-name-list', riderNames, true);
        this.setDataForTableSummary('this-year', this.defaultPremium);
        this.setDataForTableSummary('next-year', this.defaultPremium);
      });
    });
  }

  filledUpPremiumOnShieldPlanAndRiderChange(isClient = false) {
    const inputs = ['.policy-name-list', '.rider-name-list', '.annual-increment-input', '.insurance-person-input', '.discount-factor-input'];
    $(`#automatic-extraction ${inputs.join(',')}`).on('change', () => {
      this.calculateCpfMedisaveAndCashOutlay(isClient);
    });
    this.calculateCpfMedisaveAndCashOutlay(isClient);
  }

  calculateCpfMedisaveAndCashOutlay(isClient = false) {
    let shieldPlanName = $('.policy-name-list').val();
    let riderName = $('.rider-name-list').val();
    let policyCreatedDate = $('.policy-created-date').data('value');
    let annualIncrement = $('.annual-increment-input').val();
    let insurancePerson = $('#automatic-extraction input[name*="[insurance_person]"]:checked').val();
    if (!shieldPlanName) {
      this.setDataForTableSummary('this-year', this.defaultPremium);
      this.setDataForTableSummary('next-year', this.defaultPremium);
      return;
    }

    let url = ""
    if(isClient) {
      url =
        Routes.calculate_cpf_medisave_and_cash_outlay_clients_shield_premiums_path({
          shield_plan_name: shieldPlanName,
          rider_name: riderName,
          policy_created_date: policyCreatedDate,
          annual_increment: annualIncrement,
          insurance_person: insurancePerson,
        })
    } else {
      url = 
        Routes.calculate_cpf_medisave_and_cash_outlay_consultant_shield_premiums_path({
          shield_plan_name: shieldPlanName,
          rider_name: riderName,
          policy_created_date: policyCreatedDate,
          annual_increment: annualIncrement,
          insurance_person: insurancePerson,
        })
    }
    $.get(url).done((data) => {
      this.setDataForTableSummary('this-year', data.current_age_value);
      this.setDataForTableSummary('next-year', data.age_next_birthday_value);
      $('.date-uploaded').text(formatDate(new Date(data.date_uploaded)));
    });
  }

  setDataForTableSummary(selector, value) {
    $(`.cpf-medisave-${selector}`).text(formatCurrency(value.cpf_medisave));
    $(`.cash-outlay-${selector}`).text(formatCurrency(value.cash_outlay));
    $(`.total-premium-${selector}`).text(formatCurrency(value.total_premium));
  }

  allowRiderNameSelectionClear() {
    $('.rider-name-list').select2({
      allowClear: true,
      placeholder: 'Select a value',
      dropdownParent: $('.rider-name-list').parent(),
    });
  }
}
